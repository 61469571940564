
button {
    background: #f5f5f5;
    border: none;
    cursor: pointer;
}

.container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    bottom:85px;
}


.card {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
    background-color: white;
    border-radius: 18px;
    padding: 40px;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, .08);
    transition: all .3s cubic-bezier(0, 0, .5, 1);

    justify-content: space-between;
    width: 300px;
    margin: 10px;

}


.card:hover {
    background-color: #72aee8;
    transform: translateY(-10%);
}

.card__img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    padding: 5px;
    border-radius: 10px;
}

.card__body {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #13385c;
    background-color: #72aee8;
    color: white;
    box-shadow: 3px 2px 8px #1f258c;

}


a {
    text-decoration: none;
    color: white;
}

.card__title {
    margin: 0;
    font-size: 1em;
    font-weight: normal;
}

@media screen and (max-width: 700px) {
    .photo img {
        height: 13em;
        margin-top: 50px;
    }

    button {
        background: none;

        border: none;
        cursor: pointer;
    }

    button {
        height: 100%;
    }


    .section {
        height: 50px;
    }
}
