* {
    margin: 0;

    padding: 0;

    box-sizing: border-box;
}

body {
    background: #fcfcfc;

    font-family: sans-serif;

}


@media screen and (max-width: 700px) {
    .photo img{
        height:13em;
    }
    button {
        background: none;

        border: none;
        cursor: pointer;
    }
    button{
        height: 100%;
    }


}