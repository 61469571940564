.css-6hp17o-MuiList-root-MuiMenu-list{
    font-weight: bold;
    text-align: center;
    background-color: black;
}
h2{
    color:black ;
}
@media screen and (max-width: 700px) {
    .photo img{
        height:13em;
    }
    button {
        background: none;

        border: none;
        cursor: pointer;
    }
    button{
        height: 100%;
    }



}