@import 'https://fonts.googleapis.com/css?family=Poppins:400,500';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;500;700&display=swap');
@import url("https://fonts.googleapis.com/css?family=Mea Culpa");
@import url('https://fonts.googleapis.com/css2?family=Dancing Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Source+Code+Pro');
@import url('https://fonts.googleapis.com/css2?family=Beau Rivage&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin Sans&family=Source+Code+Pro');
@import url('https://fonts.googleapis.com/css2?family=Borel&family=Cormorant+Upright&display=swap');
/* background: radial-gradient(rgb(192, 228, 255), rgb(174, 16, 108)); */

@import url(//fonts.googleapis.com/css?family=Lato:300:400);

body {
    margin:0;
}




.header {
    position:relative;
    text-align:center;
    background: linear-gradient(60deg, rgb(54, 218, 204) 0%, rgb(8, 88, 98) 100%);
    color:white;
}

.inner-header {
    height:10vh;
    width:100%;
    margin: 0;
    padding: 0;
}

.flex { /*Flexbox for containers*/
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.waves {
    position:relative;
    width: 100%;
    height:15vh;
    margin-bottom:-7px; /*Fix for safari gap*/
    min-height:100px;
    max-height:150px;
}



/* Animation */

