.clone_git{
    width: 50%;
    margin: auto;
}
li{
    text-align: center;

}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .clone_git{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

    }
    li{
        text-align: center;

    }
}